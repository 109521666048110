html {
  background-color: #E6D5AC;
  color:white;
}

body {
}

#root {
}

.App {
  display: flex;
  justify-content: center;
}

.Contents {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.Sleepy-header {
  display:flex;
  color: white;
  justify-content: center;
  background-color: #1F3D61;
}

.header-contents {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 1160px;
  padding-left: 5%;
}

.header-title {
  display: flex;
  /*background-color:blue;*/
}

.drop-downs {
  display: flex;
  transition: all .15s linear 0s;
  margin-left: auto;
  padding: 0px 20px 0px 20px;
  border-left: black solid;
  border-right: black solid;
  /*background-color: red;*/
}

.drop-downs:hover {
  cursor: pointer;
  box-shadow: 5px 10px 20px black inset;
}

.About-me-container {
  display: flex;
  justify-content: center;
  background-color: #3296AD;
  box-shadow: 0px 0px 10px black inset;
}

.About-me-contents {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 1160px;
}

.max-container {
  display: flex;
}

.my-face {
  max-width: 150px;
  border: 10px solid #73C7C4;
  border-radius: 5px;
  margin: 20px;
}

.Footer-container {
  margin-top: auto;
  display: flex;
  justify-content: center;
}

.Footer-content > a > img {
  margin: 20px;
  filter: black; /* IE6-9 */
  -webkit-filter: brightness(0%); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: brightness(0%); /* Microsoft Edge and Firefox 35+ */
}

.Tile-area {
  display: flex;
  justify-content: center;
}

.Tile-area-contents {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 1160px;
  flex-flow: row wrap;
  justify-content: center;
}

.Tile-container {
  background-color: #D19846;
  transition: all .15s linear 0s;
  box-shadow: 10px 10px 10px black inset;
  display: flex;
  width:300px;
  height:300px;
  margin:5px;
  border-radius: 10px;
  justify-content: center;
}

.Tile-container:hover {
  box-shadow: 20px 20px 10px black inset;
}

.Tile-contents {
  display: flex;
  transition: all .15s linear 0s;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: -5px;
}

.Tile-contents > a {
  display:flex;
  transition: all .15s linear 0s;
  justify-content: center;
  flex-flow: column;
  width:100%;
  height: 100%;
}

.Tile-container:hover .Tile-contents {
  transform: translate(10px, 10px);
}


.Tile-contents > a > img {
  height: 150px;
  object-fit: contain;
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

.Tile-contents > a > h3 {
  text-align:center;
  text-decoration: none;
  margin: 1em;
}

a {
  color: white;
  font-size: 20px;
  text-decoration: none; /* no underline */
}

